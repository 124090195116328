/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactFooter from '../contactFooter/index'
import contactusbanner from '../../assets/contactusbanner.png'
import axios from 'axios';
import HiringIcon from '../HiringIcon/hiring';
import './contactus.css';
import GoToTop from '../GoToTop/GoToTop';
function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyname: '',
    message: ''
  })

  const { name, email, companyname, message } = formData;

  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false)
  const [showNameError, setShowNameError] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const [showCompanyNameError, setShowCompanyNameError] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)

  const [formSubmitBtn, setFormSubmitBtn] = useState(false)
  const submitContactForm = (e) => {
    e.preventDefault()
    setFormSubmitBtn(true);
    axios.post('https://sheet.best/api/sheets/a7e21029-4671-4c4a-972c-378773efc9fc', formData)
      .then((res) => {
        console.log(res, "res")
        setFormData({
          name: '',
          email: '',
          companyname: '',
          message: ''
        })
        setFormSubmitSuccess(true)
        setShowNameError(false)
        setShowEmailError(false)
        setShowCompanyNameError(false)
        setShowMessageError(false)
        setFormSubmitBtn(false)
      })
      setTimeout(() => {
        setFormSubmitSuccess(false)
      }, 5000)
  }

  const validateName = (data) => {
    if (data.length !== 0) {
      setShowNameError(true)
    } else {
      setShowNameError(false)
    }
  }
  const validateEmail = (data) => {
    const emailValid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (emailValid) {
      setShowEmailError(true)
    } else {
      setShowEmailError(false)
    }
  }
  const validateCompanyName = (data) => {
    if (data.length !== 0) {
      setShowCompanyNameError(true)
    } else {
      setShowCompanyNameError(false)
    }
  }

  const validateMessage = (data) => {
    if (data.length !== 0) {
      setShowMessageError(true)
    } else {
      setShowMessageError(false)
    }
  }
  return (
    <div>
      <Helmet>
        <title>Contact Us - KibbCom.com</title>
        <meta name="description" content="We are here to answer any questions you have about us. Reach out to us and we'll respond as soon as possible." />
      </Helmet>
      <section className='image-container'>
        <div className='HeadElement'>
          <img src={contactusbanner} alt='' className='imgcls'></img>
        </div>
        <div className='head-content'>
          <h1 className=' HeadText ' >Accelerate your growth</h1>
          <h3 className=' SubHeadText '>both professionally and personally</h3>
        </div>
      </section>

      <section className='contactPageForm py-5'>
        <div className='container'>
          <div className='row'>
            <h4 className='pb-4' style={{ fontWeight: 900 }}>Contact Us</h4>
          </div>
          <div className='row'>
            {
              formSubmitSuccess && <p className="alert alert-success">
                <strong>Message sent successfully.</strong>
              </p>
            }
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                <input type="text" name="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  value={name}
                  onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }); validateName(e.target.value) }} required />
                
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Email Id</label>
                <input type="email" name='email' id="exampleInputPassword1"
                  className="form-control"
                  value={email}
                  onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }); validateEmail(e.target.value) }} required />
               
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword2" className="form-label">Company Name</label>
                <input type="text" name='companyname' className="form-control" id="exampleInputPassword2"
                  value={companyname}
                  onChange={(e) => { setFormData({ ...formData, [e.target.name]: e.target.value }); validateCompanyName(e.target.value) }} required />

              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword3" className="form-label">Message</label>
                <textarea name='message' rows={6} className="form-control" id="exampleInputPassword3"
                  value={message}
                  onChange={(e) => {setFormData({ ...formData, [e.target.name]: e.target.value }); validateMessage(e.target.value) }} required />
              </div>
              <button className="btn btn-primary customBtn" disabled={!showEmailError || !showNameError || !showCompanyNameError || !showMessageError || formSubmitBtn } onClick={(e) => submitContactForm(e)}>Submit</button>
            </div>
          </div>
        </div>
      </section>
      <HiringIcon />
      <ContactFooter />
      <GoToTop />
    </div>

  );
}

export default ContactUs;