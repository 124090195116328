
import React from 'react';
import {Helmet} from "react-helmet";
import {FaArrowRight} from 'react-icons/fa'
import {
  Card,
  CardTitle,
  CardText,
  Col,
  Button,
  Row,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // Carousel,
  // CarouselItem,
  // CarouselIndicators,
  // Pagination,
  // PaginationItem,
  // PaginationLink
} from 'reactstrap';
import './careers.css';

import Headimage from '../../assets/Headimage.png'
// import Bitmap from '../../assets/Bitmap.png';
import Footer from '../Footer'

import job1 from '../../assets/JD1.pdf'
import job2 from '../../assets/JD2.pdf'
import job3 from '../../assets/JD3.pdf'
import GoToTop from '../GoToTop/GoToTop';
// const items = [
//   {
//     id: 1,
//     altText: 'Slide 1',
//     caption: 'Summer Internship Program ',
//     src: { Bitmap },
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 2,
//     src: { Bitmap },
//     altText: 'Slide 2',
//     caption: 'Summer Internship Program ',
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 3,
//     src: { Bitmap },
//     altText: 'Slide 3',
//     caption: 'Summer Internship Program ',
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 4,
//     src: { Bitmap },
//     altText: 'Slide 4',
//     caption: 'Summer Internship Program ',
//     Headertext: 'Life at KibbCom'
//   },
// ];
function Careers() {

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggle = () => setDropdownOpen((prevState) => !prevState);


  // const [activeIndex, setActiveIndex] = useState(0);
  // const [animating, setAnimating] = useState(false);

  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };

  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };

  // const goToIndex = (newIndex) => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  // const slides = items.map((item) => {
  //   return (
  //     <CarouselItem
  //       className="custom-tag"
  //       tag="div"
  //       key={item.id}
  //       onExiting={() => setAnimating(true)}
  //       onExited={() => setAnimating(false)}
  //     >
  //       <div className='row careerPageTexts'>
  //         <div className='col-lg-6 col-md-12 col-sm-12'>
  //         <div className='carousel-caption '>
  //               <h3> {item.Headertext}</h3>
  //               <p>{item.caption}</p>
  //             </div>
          
  //           <Button className='learn'>Learn More</Button> 
  //         </div>

  //         <div className='col-lg-6 col-md-12 col-sm-12'>
  //           <img src={item.src.Bitmap} alt={item.altText} width={638} height={485} />

  //         </div>

  //       </div>


  //     </CarouselItem>
  //   );
  // });

  let positions = [
    { title: 'Data Science Engineer', role:"DSE", description: "Expertise in Power BI, machine learning algorithms, deep learning algorithms, and possess the ability to manage end to end data science projects", exp:" Exp (3+ Years)", link: job1 },
    { title: 'iOS developer', role:"iOSD", description: "Expertise in crafting high-performance iOS apps with Swift, BLE integration, RESTful APIs, and adherence to design principles for seamless user experiences",exp:"Exp (4 - 7 Years)", link: job2 },
    { title: 'Senior Embedded Engineer', role: "SeniorEmbeddedEngineer", description: "Excellent  logical  experience   to  handle  <br> Arithmetic   operations  using  Boolean  logic,  Integers,  and Hexadecimal number system  \n", exp:" Exp (7 - 12 Years)", link: job3 }
  ]

  const downloadJD = (jd) => {
    window.open(jd.link, "_blank");
  }

  return (<>
      <Helmet>
        <title>Career - KibbCom.com</title>
        <meta name="description" content="We are looking for the world's brightest people from different countries, ethnicities, backgrounds, cultures, and religions to join our team." />
      </Helmet>
      <section className='image-container'>
        <div className='HeadElement'>
          <img src={Headimage} alt='' className='imgcls'></img>
        </div>
        <div className='head-content'>
          <h1 className=' HeadText ' >Accelerate your growth</h1>
          <h3 className=' SubHeadText '>both professionally and personally</h3>

        </div>
      </section>


      <section className='container' id='careerPageLink'>
        <div className='row'>
            <p className='SubText'>
              We are looking for the world's brightest people from different countries, ethnicities, backgrounds, cultures, and religions to join our team.
              We ensure that you will be surrounded by talented and passionate colleagues and will receive the collaborative energy you need to assist our clients and advance your career.
              We value, celebrate and are committed to inclusion and diversity.
            </p>
        </div>
      </section>
      <section  className='container pb-5'>
        <div className='row'>
        {/* <Row>
          <Col sm="10"><h3 className='OpeningText'>All Openings</h3></Col>
          <Col sm="1" className='OpeningRole' style={{textAlign: 'right'}}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
              <DropdownToggle caret color='white' className='RoleButton'>Role: All</DropdownToggle>
              <DropdownMenu >
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col sm="1" className='d-none d-md-block' style={{textAlign: 'right'}}>
            <Button className='ResetButton'>Reset</Button>
          </Col>
        </Row> */}

        <Row className='Openings ' >
          {positions.map((position, i) => (
            <Col sm="6" md="4" lg="4" className='cardstyle' key={i+1}>
              <Card
                body
                className="my-2 p-3" onClick={() => downloadJD(position)}
              >
                <CardTitle tag="h5">
                  {position.title}
                </CardTitle>
                <CardText>
                  {position.description} <br/>
                  <span> {position.exp}</span>
                </CardText>
                {/* <CardFooter> */}
                <Button color="light" className='ApplyButton' >
                  Apply Now <FaArrowRight></FaArrowRight>
                </Button>
                {/* </CardFooter> */}
              </Card>
            </Col>

          ))}
        </Row>
        {/* <Row>
          <Col sm="4" className='Pagestyle'>Page 1/1: Showing 1-3 of 3 Results</Col>
          <Col sm="4"></Col>
          <Col sm="4" className='Paginationstyle'>
            <Pagination>
              
              <PaginationItem>
                <PaginationLink
                  href="#"
                  previous
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  href="#"
                  next
                />
              </PaginationItem>
              
            </Pagination>
          </Col>
        </Row> */}
        </div>
      </section>

    {/* <section className='careerBlog'>
      <div>
        
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>


      </div>
    </section> */}

    <Footer />
    <GoToTop />
  </>
  );
}

export default Careers;
