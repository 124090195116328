import React from "react";
import {Helmet} from "react-helmet";
import { Label } from "reactstrap";
import './Home.css';

// import Bitmap from '../../assets/Bitmap.png';
// import ImageCarousel from "../carouselComponent/ImageCarousel";
import Footer from '../Footer'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from "../GoToTop/GoToTop";
// const items = [
//   {
//     id: 1,
//     altText: 'Slide 1',
//     caption: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
//     src: { Bitmap },
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 2,
//     src: { Bitmap },
//     altText: 'Slide 2',
//     caption: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 3,
//     src: { Bitmap },
//     altText: 'Slide 3',
//     caption: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
//     Headertext: 'Life at KibbCom'
//   },
//   {
//     id: 4,
//     src: { Bitmap },
//     altText: 'Slide 4',
//     caption: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
//     Headertext: 'Life at KibbCom'
//   },
// ];
function Home() {
  
  return (<>
    <Helmet>
        <title>KibbCom</title>
        <meta name="description" content="KibbCom is a Swedish-Indian deep-tech company specializing in communication intensive software development, providing independent research, design and development services." />
      </Helmet>
    <section className="section-img">
      <div className="section-height-home">
        <div className="HeadElement-home row">
          <Label className="text-left HeadText-home ">
            <p>
              Welcome <br /> to KibbCom
            </p>
          </Label>
        </div>
        <div className=" row">
          <p className="text-left SubHeadText-home ">
            Taking inspiration from technology's <br /> potential and evolution
          </p>
        </div>
      </div>
      <div className="first-home">
        <div className="first-home-text">
          <p className="first-home-text-size">
            KibbCom is a <b>Swedish-Indian deep-tech company specializing in communication intensive  software development, providing
              independent research, design and development services. </b>
            KibbCom is known for its proven ability and domain expertise in crafting a myriad of state-of-the-art software solutions.
          </p>
        </div>
      </div>
    </section>

    <section className="sectionSecontionHome">
      <div className="container">
        <div className=" row">
          <div className="SubText-home">
            <p>
              A leader in communication driven software products and services, KibbCom India offers research, design and development services,
              inspired and guided by the way things work in the world. </p>
            <p>Our range of services is offered in an INDEPENDENT, IMPARTIAL AND CONFIDENTIAL way adhering to our company's four underlying
              pillars; Passion, People, Process, and Perseverance. </p>
            <p>Our engineers, developers, and testers have expertise and experience with IOT embedded design, and Google Cloud, AWS, Azure,
              Firebase/store, Docker, Kubernetes, Hashicorp, Mongo-related application/system development services, API design and implementation.</p>
            <p> We practice Continuous Integration and Deployment of our assignment deliveries using Agile and Lean methodologies to ensure
              best-in-class deliverables tailored exclusively to the needs of our clients. </p>
            <p>As part of our mission to make the world a more technologically inclusive place, we offer customized solutions that enable us to
              create a better world for all.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="whyKibbCom">
      <div className="container">
        <div className="row">
          <div className="heading">
            <h2>Why KibbCom</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <div className="whycontentblock">
              <h3>Divergent Thinking and out-of-the-box Solutions</h3>
              <p>As a team, we follow unconventional methodologies accompanied by creative divergent thinking that is not restricted by the rules and traditions of our industry to build solutions that serve the purpose and have a bigger impact</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <div className="whycontentblock">
              <h3>Value-Driven Management & Leadership</h3>
              <p>Our organization strives to promote a culture of self-thinking members, where waste is minimized, and efficiency is increased as a result of continuous improvement. We believe in a conscious commitment to our core values at all levels of leadership to create a better work culture</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <div className="whycontentblock">
              <h3>Punctuality</h3>
              <p>As a domain expert company that emphasizes punctuality in all areas of client service, we help businesses optimize their technology and workflow within tight deadlines. We value our customers’ time and goals and endeavor to provide optimum results in stipulated time frames</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <div className="whycontentblock">
              <h3>High-Quality Deliverables</h3>
              <p>Our goal as a company is to exceed our customers' expectations by consistently providing high-quality deliverables to them. We continually challenge ourselves to improve and advance as a company in order to keep moving toward "the NEXT" great thing</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <section fluid className="blogSection">
      <Container>
        <h2 className="row">Blog</h2>
        <p className="row subHeadingBlog mb-4">Building a community through knowledge sharing</p>
        <ImageCarousel
          items={items}
        />
      </Container>
    </section> */}
    <HiringIcon />
    <Footer />
    <GoToTop />
  </>
  );
}

export default Home;
