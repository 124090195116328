import React from 'react';
import {Helmet} from "react-helmet";
import './Life.css';

// import Stack from '@mui/material/Stack';  
import { Container, Row, Col, Button } from 'reactstrap';
// import ProductSlider from '../../ProductSlider';
import ProductSlider from '../ProductSlider/ProductSlider';
import Banner from '../Banner/Banner';
import Nisar from '../Nisar/nisar';
import Footer from '../Footer'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';
function Life() {
  return (
    <div>
      <Helmet>
        <title>Life at KibbCom - KibbCom.com</title>
        <meta name="description" content="With a focus on representation, engagement, culture, accountability, and continuous improvement, KibbCom makes it possible for creative people around the world to connect, grow, and make an impact." />
      </Helmet>
      <Banner />
      <div className='body-div'>
        <Nisar />
      </div>
      <Container className='py-4'>
        <div className='row'>
          <ProductSlider />
        </div>
      </Container>
      <HiringIcon />
     <Footer />
     <GoToTop />
    </div>
  )
}

export default Life