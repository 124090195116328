import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa'
import KibbcomLogo from '../../assets/Kibbcomlogo.svg';
import './Navbar.css'
import { Link, NavLink } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const Navbar = ({ toggle }) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const change = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <div className='navbar-home'>
        <div className='navbar-container'>
          {/* <div className='navbar-logo' to='/' > */}
          <Link className='navbar-logo' to="/home">
            <img className='logo' alt="logo" height={40} src={KibbcomLogo}></img>
            KibbCom
          </Link>

          {/* </div> */}
          <div className='mobile-icon' onClick={toggle} >
            <FaBars />
          </div>
          <ul className='navbar-menu'>
            <li className='navbar-item'>
              <NavLink className='navbar-link' activestyle={{ color:'#FD5F07' }} to="/home">Home</NavLink>
            </li>
            <li className='navbar-item'>
              <NavLink className='navbar-link' activestyle={{ color:'#FD5F07' }} to="/services">Services</NavLink>
            </li>
            <li className='navbar-item'>
              <NavLink className='navbar-link' activestyle={{ color:'#FD5F07' }} to="/blog">Blogs</NavLink>
            </li>
            <li className='navbar-item'>
              <NavLink className='navbar-link' activestyle={{ color:'#FD5F07' }} to="/values">Values</NavLink>
            </li>
            <li className='navbar-item'>
              <NavLink className='navbar-link' activestyle={{ color:'#FD5F07' }} to="/careers">Careers</NavLink>
            </li>
            {/* <li className='navbar-item'>
              <Link className='navbar-link' to="/aboutus">About Us</Link>
            </li> */}
            <li>
            <Dropdown isOpen={dropdownOpen} toggle={change} className='navbar-item' >
              <DropdownToggle caret color='white' className='title-menu-item'>About Us</DropdownToggle>
              <DropdownMenu  className='dropdown-menu-item'>
                <DropdownItem>
                  <NavLink className='navbar-dropdown-item navbar-link' activestyle={{ color:'#FD5F07' }} to="/aboutus">Our Team</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink className='navbar-dropdown-item navbar-link' activestyle={{ color:'#FD5F07' }} to="/life">Life at KibbCom</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            </li>
          </ul>
          <div className='nav-btn'>
            {/* <div class="search-container">
              <form action="/search" method="get">
                <input class="search expandright" id="searchright" type="search" name="q" placeholder="Search" />
                <label class="button searchbutton" for="searchright"><span class="mglass">&#9906;</span></label>
              </form>
            </div> */}
            {/* <FaSearch color='white' className='Fasearch'></FaSearch> */}
            <Link className='nav-btn-link' to="/contact" >Contact</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar