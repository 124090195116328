import * as React from 'react';
import './Banner.css'

function Banner() {

    return(
        <div>
            <div className='banner-container'>
                <div className='text'>
                    <h1 className='main-text'>Life at<br></br>Kibbcom</h1>
                </div>
                <div className='first'>
                <div className='first-text'>
                  <p className='first-text-size'>With a focus on <b>representation, engagement, culture, accountability, and continuous improvement,</b> KibbCom makes it possible for creative people around the world to <b>connect, grow,</b> and make an impact. We put our people at the center of everything we do, and champion <b>curiosity and connectivity</b> to deliver the best experiences.</p>
                </div>
            </div>
            </div>
            
            
        </div>
    )
}

export default Banner