/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';
import blogBanner from '../../assets/blogBanner.png'
import '../Blog/blog.css'
import electricVehicle from '../../assets/electricVehicle.png'
import xray from '../../assets/xray.png'
import iotdevice from '../../assets/iotdevice.png'
import {FaArrowRight} from 'react-icons/fa'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';
function Blog() {

    return (
        <div>
            <Helmet>
                <title>Blog - KibbCom.com</title>
                <meta name="description" content="The ownership of electric vehicles is growing exponentially across the globe." />
            </Helmet>
            <section className='blog-image-container'>
                <div className='blog-HeadElement'>
                    <img src={iotdevice } alt='blog banner' className='blog-imgcls'></img>
                </div>
                <div className='blog-head-content'>
                    <h1 className='blog-HeadText'>IoT and<br/> Industry 4.0 : <br/>The Future of Manufacturing</h1>
                </div>
            </section>

            <section className='blogPageSection py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 col-md-10 col-sm-12'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
                                    <div className='card'>
                                        <img src={electricVehicle} alt='blog 1' className='card-img-top'></img>
                                        <div className='card-body'>
                                            <h4 className='card-title'><Link to="/blog/types-of-electric-vehicles" className='card-link'>The Different types of Electric Vehicles</Link></h4>
                                            <p className='card-text'>The ownership of electric vehicles is growing exponentially across the globe.</p>

                                            <Link to="/blog/types-of-electric-vehicles" className='btn btn-light ReadMore'>Read More <FaArrowRight></FaArrowRight></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
                                <div className='card'>
                                        <img src={xray} alt='blog 1' className='card-img-top'></img>
                                        <div className='card-body'>
                                            <h4 className='card-title'><Link to="/blog/blog/detection-of-effusion" className='card-link'>Detection of Effusion in Chest X-ray images using CNN</Link></h4>
                                            <p className='card-text'>The ownership of electric vehicles is growing exponentially across the globe.</p>

                                            <Link to="/blog/detection-of-effusion" className='btn btn-light ReadMore'>Read More <FaArrowRight></FaArrowRight></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
                                <div className='card'>
                                        <img src={iotdevice} alt='blog 1' className='card-img-top'></img>
                                        <div className='card-body'>
                                            <h4 className='card-title'><Link to="/blog/iot-and-industry-4.0" className='card-link'>IoT and Industry 4.0: The Future of Manufacturing </Link></h4>
                                            <p className='card-text'>In the ever-evolving landscape of manufacturing, two transformative forces are reshaping...   </p>

                                            <Link to="/blog/iot-and-industry-4.0" className='btn btn-light ReadMore'>Read More <FaArrowRight></FaArrowRight></Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12'>
                            <div className='blogCategory'>
                                <h5><strong>Categories</strong></h5>
                                <ul>
                                    <li>eMobility</li>
                                    <li>Health</li>
                                    <li>IoT and Industry 4.0</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HiringIcon />
            <Footer />
            <GoToTop />
        </div>

    );
}

export default Blog;