import React, { useEffect, useState } from 'react';
// import { FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaTimes } from 'react-icons/fa'
import './hiring.css';

function HiringIcon() {
    const [showCard, setShowCard] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowCard(false);
        }, 2000);
    }, [])

    const hideBlock = () => {
        setShowCard(false)
    }

    return (
        <>
            {
                showCard ?
                    <div className='hiringCardFixed' id='hiringCard'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='heading'>
                                    <h5 className='card-title'>We're Hiring!</h5>
                                    <button className='closeCard' onClick={() => hideBlock()}> <FaTimes /> </button>
                                </div>
                                <Link className='btn cartPositionBtn' to="/careers">Explore Positions <FaArrowRight></FaArrowRight></Link>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {/* <div className='hiringIcon'>
                <div className='iconCircle'>
                    <div class="warp" aria-labelledby="warp-label">
                        <span id="warp-label" class="warp__placeholder">
                            We are hiring
                        </span>

                        <span aria-role="presentation">
                            <span class="warp__0">
                                W
                            </span>
                            <span class="warp__1">
                                e
                            </span>
                            <span class="warp__2">

                            </span>
                            <span class="warp__3">
                                a
                            </span>
                            <span class="warp__4">
                                r
                            </span>
                            <span class="warp__5">
                                e
                            </span>
                            <span class="warp__6">

                            </span>
                            <span class="warp__7">
                                h
                            </span>
                            <span class="warp__8">
                                i
                            </span>
                            <span class="warp__9">
                                r
                            </span>
                            <span class="warp__10">
                                i
                            </span>
                            <span class="warp__11">
                                n
                            </span>
                            <span class="warp__12">
                                g
                            </span>
                        </span>
                    </div>
                    <FaRocket className='faIcon' />
                </div>
                <div className='hiringText'>

                </div>
            </div> */}
        </>
    )
}
export default HiringIcon