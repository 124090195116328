import React from "react";
import './ProductSlider.css';
import FoosballTournament from '../../assets/Foosball.png';
import Gym from '../../assets/Gym.png';
import WorkEnvironment from '../../assets/MicrosoftTeams-image-work-env.png';
import badminton from '../../assets/badminton.png';
import Cricket from '../../assets/cricket1.png';

const Card = (props) => (
  <div sm='12' md='12' lg='6'>
    <div className="card">
      <img src={props.imgUrl} alt={props.alt || "Image"} />
      <div className="card-content">
        <h2>{props.title}</h2>
        <p>{props.content}</p>
      </div>
    </div>
  </div>
);

const CardContainer = (props) => (
  <div className="cards-container">
    {props.cards.map((card) => (
      <Card title={card.title}  imgUrl={card.imgUrl} />
    ))}
  </div>
);

class ProductSlider extends React.Component {
  render() {
    const cardsData = [
      {
        id: 1,
        title: "Office Gym",
        content: "Clark Kent",
        imgUrl: Gym,
      },
      {
        id: 2,
        title: "Foosball KPL",
        content: "Bruce Wayne",
        imgUrl: FoosballTournament
      },
      {
        id: 3,
        title: "Work environment ",
        content: "Peter Parker",
        imgUrl: WorkEnvironment
      },
      {
        id: 4,
        title: "Badminton Tournament",
        content: "Peter Parker",
        imgUrl: badminton
      },
      {
        id: 5,
        title: "Cricket Tournament",
        content: "Peter Parker",
        imgUrl: Cricket
      },
    ];

    return (
      <CardContainer cards={cardsData} />
    );
  }
}

export default ProductSlider