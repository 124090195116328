import * as React from 'react';
import './nisar.css';
import { Container, Row, Col, Button } from 'reactstrap';

function nisar() {
  return (
    <div className='body pb-4'>
      <Container>
        <Row className='mb-4'>
          <Col
            xs="12" md="12" lg="5"
          >
            <div>
              <Button className='col-btn mb-3'>Collaborate, Learn and Grow</Button>
              <p>Invest your time, care, and expertise to ensure collective impact. Look for opportunities to collaborate across the organization to make great decisions. Be biased towards collaborative decision-making</p>
            </div>
          </Col>
          <Col
            xs="12" md="12" lg="5"
          >
            <div>
              <Button className='col-btn mb-3'>Create Amazing Solutions</Button>
              <p>Deliver an experience that customers adore. We put our customers first, understand the market and analyze the changing trends. Customers want something better, let's deliver it TOGETHER!</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs="12" md="12" lg="5"
          >
            <div>
              <Button className='col-btn mb-3'>Think Out-of-the-Box</Button>
              <p>We believe that there is always a better way. Whether you get there in small steps or giant leaps, success only comes from constant experimentation. Let’s collaborate to create something out-of-the-box</p>
            </div>
          </Col>
          <Col
            xs="12" md="12" lg="5"
          >
            <div>
              <Button className='col-btn mb-3'>Take Pride Together</Button>
              <p>We at KibbCom own the company together and it's our responsibility to take care of every piece of content, every piece of code, and every interaction we have with clients, applicants, candidates, and investors</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default nisar