import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import { FaArrowRight, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa'
function Footer() {
    return (
        <section className='footerSection pt-5'>
            <Container >
                <div className='row'>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                        <ul className='footMenu list-unstyled'>
                            <li><Link to="/aboutus">Our Team</Link></li>
                            <li><Link to="/life">Life at KibbCom</Link></li>
                            <li><Link to="/blog">Blogs</Link></li>
                            <li><Link to="/values">Values</Link></li>
                            <li><Link to="/careers">Careers</Link></li>
                        </ul>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                        <h5 className='footServiceHeading'>Services</h5>
                        <ul className='footServiceLink list-unstyled'>
                            <li><HashLink to="/services#embedded" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Embedded</HashLink></li>
                            <li><HashLink to="/services#iot" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>IoT</HashLink></li>
                            <li><HashLink to="/services#electricVehicle" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>eMobility</HashLink></li>
                            <li><HashLink to="/services#cloudComputing" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Cloud Computing</HashLink></li>
                            <li><HashLink to="/services#industry40" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>Industry 4.0</HashLink></li>
                        </ul>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <p className='footBodyContent'>We’re hiring! <br /> Learn about careers at KibbCom</p>
                                <Link className='btn positionBtn' to="/careers">Explore Positions <FaArrowRight></FaArrowRight></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className='row contactFooterReverse'>
                    <div className='col-lg-6 col-sm-12 col-xs-12'>
                        <p>© 2023 KibbCom</p>
                    </div>
                    <div className='col-lg-6 col-sm-12 col-xs-12'>
                        <ul className='footSocialLink list-inline'>
                            <li className='list-inline-item'>
                                <a href="https://www.linkedin.com/company/kibbcom-india/" target="_blank">
                                    <FaLinkedin className='linkedin socialIcon' />
                                </a>
                            </li>
                            <li className='list-inline-item'>
                                <a href="https://www.facebook.com/kibbcom" target="_blank"><FaFacebook className='fb socialIcon' /></a>
                            </li>
                            <li className='list-inline-item'>
                                <a href="https://www.instagram.com/kibbcom/?hl=en" target="_blank"><FaInstagram className='instagram socialIcon' /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Footer;