/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer/index'
import '../BlogDetails/blogdetails.css'
import blogBanner from '../../assets/blogBanner.png'
import blog1 from '../../assets/blog-1-img.png'
import blog2 from '../../assets/blog-2.png'
import blog3 from '../../assets/blog-3.jfif'
import blog4 from '../../assets/blog-4.png'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';

function BlogElectricVehicles() {
    
    return (
        <div>
             <Helmet>
                <title>Blog 1 Electric vehicles - KibbCom.com</title>
                <meta name="description" content="The ownership of electric vehicles is growing exponentially across the globe. With depleting fossil fuels and vehicular pollution becoming one of the biggest environmental issues of this century, electric vehicles commonly known as e-vehicles seem to be the future." />
            </Helmet>
            <section className='blogdetails-image-container'>
                <div className='blogdetails-HeadElement'>
                    <img src={blogBanner} alt='blog banner' className='blogdetails-imgcls'></img>
                </div>
                <div className='blogdetails-head-content'>
                    <h1 className='blogdetails-HeadText'>The Different types of Electric Vehicles</h1>
                </div>
            </section>

            <section className='blogdetailsPageSection py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 col-md-10 col-sm-12'>
                            <p>The ownership of electric vehicles is growing exponentially across the globe. With depleting fossil fuels and vehicular pollution becoming one of the biggest environmental issues of this century, electric vehicles commonly known as e-vehicles seem to be the future.</p>
                            <p>The popularity of e-vehicles has skyrocketed in the past few years and continues to increase significantly . While electric vehicles have started making their way slowly and gradually into the mainstream market globally, a significant portion of the population is unaware about the basics, types, benefits and nuances of e-vehicles.</p>
                            <p> To understand the concepts and demystify the common beliefs  centered around e-vehicles, read on ! In this article, we are going to shed light on the different types of electric vehicles and their benefits.</p>
                            <p>So get ready, fasten your seatbelts!</p>

                            <h5>Hybrid Electric Vehicles (HEVs) </h5>
                            <p>The first type that we are going to look at is the most common and most widely used type of electric vehicles. We are talking about hybrid electric vehicles. Though the underlying HEV technology has been around for decades, the major revolution came with the introduction of ‘Toyota Prius’ in 1997.  </p>
                            <p>Toyota Prius is one of the most selling vehicles of all time and is known for its iconic Prius battery. Unlike the late 90s, the current e-vehicle market provides numerous options like Kia Optima Hybrid, Ford Fusion Hybrid, Kia Niro, Hyundai Ioniq HEV, etc. </p>
                            <p>Typically, hybrid electric vehicles combine an internal combustion engine (typically a gasoline or petrol engine) with electric propulsion. While the internal combustion engine does most of the work, the electric motor assists it, primarily for the purpose of improving fuel economy. Since hybrid vehicles cannot connect to the grid to recharge their batteries, they rely on regenerative braking systems and internal combustion engines to recharge their batteries. </p>
                            <p>When a hybrid vehicle is moving, the combustion engine must run at all times, as the vehicle cannot be powered solely by battery power. However, some hybrids can travel at low speeds for a few feet before the combustion engine has to be turned on. </p>
                            <p>Compared with similar conventional cars, hybrid electric vehicles have better fuel economy and lower total cost of ownership, but they usually cost more up front. In urban environments, the Toyota Prius gets 54 mpg (miles per gallon), while on the highway, it gets 50 mpg. Other conventional gas-powered sedans, such as the Volkswagen Passat TDI, Nissan Altima, and Mazda6, get 35 mpg, 31 mpg, and 32 mpg.</p>

                            <img src={blog1} alt="Hybrid Electric Vehicles" className='blog-image' />

                            <h5>Plug-in Hybrid Electric Vehicles (PHEVs)</h5>
                            <p>Regular hybrid vehicles have smaller batteries, while plug-in hybrid electric vehicles have larger batteries and can be plugged in to charge them. PHEVs also typically have larger electric motors because they do more work. </p>
                            <p>As plug-in electric cars are equipped with larger batteries, they can propel themselves without the need for combustion engines for a longer period of time. Plug-in electric cars can go 30 to 50 miles on their batteries alone, such as the Chrysler Pacifica Hybrid, Ford C-Max Hybrid, and Honda Clarity PHEV, and the BMW i3 REx plug-in hybrid can go 126 miles on its battery before the gasoline combustion engine is required. </p>
                            <p>The option of plug-in hybrids is an excellent one for consumers who wish to have more range. A plug-in hybrid offers the flexibility of being able to quickly fill up with gasoline where charging stations may not be available for those who frequently drive very long distances. When their owners don't exceed the vehicle's all-electric range, plug-in hybrids allow their owners to drive entirely on electricity, while having the combustion engine on hand when needed. </p>
                            <p>Despite the fact that owners of plug-in hybrids will want to keep their batteries charged as often as possible to take advantage of the savings that driving on electricity provides, charging the battery is not necessary for use. When plug-in hybrids are not charged up from a wall outlet, they will function like conventional hybrid electric vehicles. Because of this, it's not a problem if the owner forgets to plug the vehicle in one day or if he drives to a location without electricity. Generally, fuel costs are higher when using a combustion engine rather than a battery pack. </p>

                            <img src={blog2} alt="Plug-in Hybrid Electric Vehicles (PHEVs)" className='blog-image' />
                            <h5>Battery Electric Vehicles (BEVs) </h5>
                            <p>A battery electric vehicle, or BEV, is a vehicle that runs entirely on batteries, unlike a hybrid or plug-in hybrid.</p>
                            <p>As a matter of fact, battery electric vehicles are so simple because they have so few moving parts that they require very little maintenance. Since there are no oil changes or tune-ups required, you save a lot of money over the life of the vehicle because you don't have to worry about these maintenance expenses. However, you do need to know how long your electric car battery will last. </p>
                            <p>Due to the fact that electric vehicles rely solely on their batteries, it is even more imperative for them to be able to recharge quickly than they are for plug-in hybrids. Therefore, it's vital that you choose a home charging solution that will fully recharge your BEV as quickly as possible, so you can enjoy it to the fullest. </p>
                            <p>A standard 120 volt level 1 charger is standard on most battery electric vehicles. However, charging the vehicle can take a very long time. As a result of their larger batteries, battery electric vehicles have much higher driving ranges and charging times than HEVs and PHEVs. Take a look at the chart below to see how large the batteries are, how long they take to charge and how long it takes to recharge. </p>

                            <div className="table-responsive">
                            <table className="table mb-3 mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">BEV Model </th>
                                        <th scope="col">Max Charge Rate </th>
                                        <th scope="col">Battery Size  </th>
                                        <th scope="col">Charge Time with L1 Charger  </th>
                                        <th scope="col">Charge time with Juice Box Pro 40  </th>
                                        <th scope="col">Driving Range </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Tesla Model S 75 D </th>
                                        <td>11.5 kW</td>
                                        <td>75 kWh </td>
                                        <td>65 hrs </td>
                                        <td>8 hrs </td>
                                        <td>237 miles </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Chevy Bolt EV  </th>
                                        <td>7.7 kW</td>
                                        <td>60 kWh </td>
                                        <td>48 hrs </td>
                                        <td>8.5 hrs </td>
                                        <td>238 miles </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Nissan Leaf e Plus   </th>
                                        <td>6.6 kW</td>
                                        <td>62 kWh </td>
                                        <td>52 hrs </td>
                                        <td>10 hrs </td>
                                        <td>226 miles </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">BMW i3  </th>
                                        <td>7.7 kW</td>
                                        <td>42 kWh </td>
                                        <td>35 hrs </td>
                                        <td>5.5 hrs </td>
                                        <td>153 miles </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            <h5>Fuel Cell Electric Vehicle (FCEVs) </h5>
                            <img src={blog3} alt="Fuel Cell Electric Vehicle" className='blog-image' />
                            <p className='mt-3'>FCEVs are powered by electric motors, which emit no tailpipe emissions. Unlike conventional electric vehicles, FCEVs do not require recharging. Instead, they create electricity onboard through an electrochemical process that combines hydrogen and oxygen to produce electricity and water. Owners use hydrogen gas at conventional gas stations to fuel their vehicles. </p>
                            <p>As with electric and hybrid vehicles, fuel cell vehicles have regenerative braking systems capable of capturing and storing energy lost during braking, similar to electric vehicles and hybrid technologies. FCEVs typically have a 300 mile range. FCEVs produce zero tailpipe emissions, only water vapor and warm air. </p>
                            <p>The selection of a fuel for an automotive fuel cell system is influenced by factors like the infrastructure for supplying fuels, the cost of fuel, the complexity and cost of storage, safety, environmental impacts, and national energy policy. In order to commercialize fuel cell vehicles in mass quantities, hydrogen must be stored properly. Hydrogen can be stored as compressed gas, liquid, or metal hydride.</p>
                            <p>Compressed gas tanks are bulky and large. Most new cars have a fuel efficiency of 20 to 30 mpg; European and Japanese cars usually have even lower fuel efficiency. Vehicles hold 10 to 16 gallons of gasoline, or 30 to 45 liters. Because hydrogen vehicles are twice as efficient as gasoline vehicles, theoretically they can store 5 to 8 kilograms of hydrogen, or 200 to 400 liters of fuel. This means that less space is needed for fuel. A liquid hydrogen tank is less bulky, but must be kept at extremely low temperatures to prevent freezing. </p>
                            <p>There has been a significant increase in interest in fuel cell vehicles in the past couple of decades, and it appears as if various governments are regaining interest. In order to make fuel cells a desirable power source in the future, improvements must be made to their stacks and manufacturing processes, as well as the development of non-polluting hydrogen sources that can be ideally produced on-site. </p>

 
                            <h5>PHEV vs BEV Costing</h5>
                            <img src={blog4} alt="PHEV vs BEV Costing" className='blog-image' />
                            <p className='mt-3'>In modern times, the cost of building an electric vehicle is largely determined by the battery, and the larger the battery, the more expensive the car. Thus, PHEVs have a greater upfront cost advantage over BEVs since they have smaller batteries. In addition to the internal combustion engine, PHEVs require ongoing maintenance, which is similar to that of a gas-powered vehicle. Over the lifetime of a PHEV, the total cost of ownership can be comparable to that of a BEV, depending on how it is driven. </p>
                            <p>Chevrolet Bolt EV, Nissan Leaf, Tesla Model 3, Hyundai Kona Electric, and Nissan Leaf are among some of the more affordable BEV models appearing on the market these days. </p>
                            <p>Across the board, new vehicles are improving both in horsepower and fuel economy, resulting in a reduction in emissions. Consumer preferences, regulations, and innovations are all contributing factors to this trend toward cleaner, more powerful vehicles </p>

                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12'>
                            <div className='blogCategory'>
                                <h6><strong>Categories</strong></h6>
                                <ul>
                                    <li>eMobility</li>
                                </ul>
                            </div> 
                        </div>
                    </div>
                </div>
            </section>
            <HiringIcon />
            <Footer />
            <GoToTop />
        </div>
    );
}

export default BlogElectricVehicles;