/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer/index'
import '../BlogDetails/blogdetails.css'
import blogBanner from '../../assets/xray.png'
import blog1 from '../../assets/lungs-000.jpg'
import blog2 from '../../assets/lungs-001.jpg'
import blog3 from '../../assets/lungs-002.jpg'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';

function BlogElectricVehicles() {

    return (
        <div>
            <Helmet>
                <title>Blog 1 Electric vehicles - KibbCom.com</title>
                <meta name="description" content="The ownership of electric vehicles is growing exponentially across the globe. With depleting fossil fuels and vehicular pollution becoming one of the biggest environmental issues of this century, electric vehicles commonly known as e-vehicles seem to be the future." />
            </Helmet>
            <section className='blogdetails-image-container'>
                <div className='blogdetails-HeadElement'>
                    <img src={blogBanner} alt='blog banner' className='blogdetails-imgcls'></img>
                </div>
                <div className='blogdetails-head-content'>
                    <h1 className='blogdetails-HeadText'>Detection of Effusion in Chest X-ray images using CNN</h1>
                </div>
            </section>

            <section className='blogdetailsPageSection py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 col-md-10 col-sm-12'>
                            <p>Effusion is the form of liquid that gets deposited in the lungs which can lead to fatal situations.</p>
                            <p>Medical science has progressed a lot to find the various forms of problems that can be dangerous and can even cost the life of a person.</p>
                            <p>Similarly, the field of Artificial Intelligence has also progressed a lot to give some level of assistance to medical professionals and help them find out which case is more sensitive and should be given more importance.</p>
                            <p>The motivation behind this idea I believe was that every day the X-rays that a radiologist gets must be around 200–300 out of which only 10–15 might get the problem of effusion. So, if we can make an intelligent machine that can see the image and classify them as ‘needs consultation’ and ‘no consultation required’, then this way the doctor can directly jump to the patients that are serious and effective treatment can be started.</p>
                            <p className='mt-4'><b>A sample image of an X-Ray with Effusion and no-effusion is given below:</b></p>
                            <img src={blog1} alt="no-effusion" className='blog-image mb-4' />
                            
                            <p>As you can see, the RHS lung is very small in size. This is because the patient’s lung has a lot of liquid deposited.</p>
                            <p>This situation is very dangerous and can lead to death if not treated immediately. Similarly, there are lungs which have slight effusion and can be treated easily because they are at the very starting phase.</p>
                            <p className='mt-4'><b>Effusion X-Ray (less acute):</b></p>
                            <img src={blog2} alt="effusion-less-acute" className='blog-image mb-4' />

                            <p>Now we can distinguish between an effusion-affected lung from a normal lung after watching the below images.</p>
                            <p className='mt-4'><b>Normal Lungs:</b></p>
                            <img src={blog3} alt="normal-lungs" className='blog-image mb-4' />

                            <p>So, now I believe we have a good understanding of effusion-affected lungs and a normal healthy lung.</p>
                            <p>Now let's jump straight away to how we can train our model to detect precisely the presence of effusion</p>
                            <p>We have images of 1024 x 1024 but our model will become very complex if we directly send this image for training.</p>
                            <p>So, we must think about the various techniques by which we can lower the size of the image and what augmentation/transformations we can use to help our model understand the image very well.</p>
                            <p>Will flipping the image vertically help?</p>
                            <p>No, we will not find a patient whose lungs are upside down, or whose neck is below lungs. So, flipping the image vertically does not make sense.</p>
                            <p>Will flipping the images sideways help?</p>
                            <p>Yes, this will help because there can be circumstances in which a patient has some problem in the left lung while in the image, we have had only the right-side lung. So, this way we can have a better dataset. Can we perform rotation to the images?</p>
                            <p>That’s a question to think about if we rotate an image 20 degrees on either side, we can definitely get new images but is it worth it? Are we going to have patients with inclined/declined lungs? Maybe, yes. So, we can give it a try. Zooming-In/Out help?</p>
                            <p>Definitely not, we will lose features along the edges of the image. So, not a good idea.</p>
                            <p>So that’s pretty much what we are going to-do/not going to do. You can try other scenarios too.</p>
                            <p>And coming back to feeding the image to NN, we will give the image after rescaling it to 1/4th of the current size. So, it will be ‘256 x 256’ instead of ‘1024 x 1024’ RESNET18 to build the model, if that performs good, we will keep it or else we can jump to a deeper RESNET model RESNET34.</p>
                            {/* <p>You can find the link to the notebook on my GitHub profile with a repo named Effusion.</p> */}

                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12'>
                            <div className='blogCategory'>
                                <h6><strong>Categories</strong></h6>
                                <ul>
                                    <li>Health</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HiringIcon />
            <Footer />
            <GoToTop />
        </div>
    );
}

export default BlogElectricVehicles;