import React from 'react'
import { FaTimes } from 'react-icons/fa';

import './Sidebar.css'
import { Link } from 'react-router-dom';

const SidebarContainer = function ({isOpen,toggle}) {
    if(isOpen){
        return <aside className='sidebar-container open'>
                    <div className='icon-div' onClick={toggle}>
            <FaTimes color='white'></FaTimes>
        </div>
        <div className='sidebar-wrapper'>
            <ul className='sidebar-menu'>
                <Link className='sidebar-link' to="/home" onClick={toggle}>Home</Link>
                <Link className='sidebar-link' to="services" onClick={toggle} >Services</Link>
                <Link className='sidebar-link' to="/blog" onClick={toggle} >Blogs</Link>
                <Link className='sidebar-link' to="/values" onClick={toggle}>Values</Link>
                <Link className='sidebar-link' to="/careers" onClick={toggle}>Careers</Link>
                <Link className='sidebar-link' to="/aboutus" onClick={toggle}>Our Team</Link>
                <Link className='sidebar-link' to="/life" onClick={toggle} >Life at KibbCom</Link>
                <Link className='sidebar-link' to="/contact" onClick={toggle} >Contact</Link>
            </ul>
            {/* <div className='side-btn-wrap'>
                <a className='sidebar-btn' to='contact'>Contact</a>
            </div> */}

        </div>
        </aside>
    }
    return <aside className='sidebar-container close'>
                <div className='icon-div' onClick={toggle}>
            <FaTimes color='white'></FaTimes>
        </div>
        <div className='sidebar-wrapper'>
            <ul className='sidebar-menu'>
                <Link className='sidebar-link' to="/home" onClick={toggle}>Home</Link>
                <Link className='sidebar-link' to="services" onClick={toggle} >Services</Link>
                <Link className='sidebar-link' to="/blog" onClick={toggle} >Blogs</Link>
                <Link className='sidebar-link' to="/values" onClick={toggle}>Values</Link>
                <Link className='sidebar-link' to="/careers" onClick={toggle}>Careers</Link>
                <Link className='sidebar-link' to="/aboutus" onClick={toggle}>About us</Link>
                <Link className='sidebar-link' to="/life" onClick={toggle} >Life at KibbCom</Link>
                <Link className='sidebar-link' to="/contact" onClick={toggle} >Contact</Link>
            </ul>
            {/* <div className='side-btn-wrap'>
                <a className='sidebar-btn' to='contact'>Contact</a>
            </div> */}

        </div>
    </aside>
}


const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} toggle={toggle} onClick={toggle}>

    </SidebarContainer>
  )
}

export default Sidebar