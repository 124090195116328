import './App.css';
import NavBarHead from './Components/NavbarHead';

function App() {

  return (
    <>
      <NavBarHead></NavBarHead>
    </>
  );
}

export default App;
