import React from 'react'
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Footer/footer.css';
import { FaFacebook, FaLinkedin, FaInstagram, FaEnvelope, FaMobileAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom';
function ContactFooter() {
    return (
        <section className='footerSection pt-5'>
        <Container >
            <div className='row'>
                <div className='col-md-4 col-lg-3 col-sm-12'>
                    <ul className='footMenu list-unstyled'>
                        <li><Link to="/aboutus">Our Team</Link></li>
                        <li><Link to="/life">Life at KibbCom</Link></li>
                        <li><Link to="/blog">Blogs</Link></li>
                        <li><Link to="/values">Values</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                    </ul>
                </div>
                <div className='col-md-4 col-lg-3 col-sm-12'>
                    <h5 className='footServiceHeading'>Contact Us</h5>
                    <ul className='footServiceLink list-unstyled'>
                        <li><FaMobileAlt /> <a href='tel:8951147138'>+91-8951147138</a></li>
                        <li><FaEnvelope /> <a href='mailto:info@kibbcom.in'>info@kibbcom.in</a></li>

                    </ul>
                </div>
                <div className='col-md-4 col-lg-3 col-sm-12'>
                    <h5 className='footServiceHeading'>Visiting Address:</h5>
                    <p className='footerVisitAddress'>Third Floor, VMR Arcade, 43/2,
                        Varthur Rd, Silver Springs Layout,
                        Munnekollal, Bengaluru,
                        Karnataka 560037.</p>
                </div>
                <div className='col-md-4 col-lg-3 col-sm-12'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2554659190496!2d77.71279781461175!3d12.955498090866469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110b13931df9%3A0x347709bf4e9f2c67!2sKibbCom%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1672808351964!5m2!1sen!2sin" ></iframe>
                </div>
            </div>

            <hr />

            <div className='row contactFooterReverse'>
                {/* <div className='col-lg-5 col-sm-12  col-xs-12'>
                    <ul className='footLink list-inline'>
                        <li className='list-inline-item'><a href="#">Sitemap</a></li>
                        <li className='list-inline-item'><a href="#">Privacy Policy</a></li>
                        <li className='list-inline-item'><a href="#">Cookie Policy</a></li>
                        <li className='list-inline-item'><a href="#">Term of use</a></li>
                    </ul>
                </div> */}
                <div className='col-lg-6 col-sm-12 col-xs-12'>
                    <p>© 2023 KibbCom</p>
                </div>
                <div className='col-lg-6 col-sm-12 col-xs-12'>
                    <ul className='footSocialLink list-inline'>
                        <li className='list-inline-item'>
                            <a href="https://www.linkedin.com/company/kibbcom-india/" target="_blank"><FaLinkedin className='linkedin socialIcon' /></a>
                        </li>
                        <li className='list-inline-item'>
                            <a href="https://www.facebook.com/kibbcom"  target="_blank"><FaFacebook className='fb socialIcon' /></a>
                        </li>
                        <li className='list-inline-item'>
                            <a href="https://www.instagram.com/kibbcom/?hl=en" target="_blank"><FaInstagram className='instagram socialIcon' /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
        </section>
    );
}

export default ContactFooter;