import React, { useState } from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Careers from '../Careers'
import Home from '../Home/Home'
import Life from '../Life/Life'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Team from '../Team/Team'
import Values from '../Values/Values'
import Services from '../Services/service'
import ContactUs from '../ContactUs/contactus'
import Blog from '../Blog/blog'
import BlogElectricVehicles from '../BlogDetails/electric-vehicle'
import BlogElectricXray from '../BlogDetails/xray'
import BlogIot from '../BlogDetails/iot'

const NavBarHead = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
    console.log(isOpen);
  }
  return (
    <>
     
      <BrowserRouter>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Routes>
            <Route index element={<Home />} />
            <Route path="life" element={<Life />} />
            <Route path="careers" element={<Careers />} />
            <Route path="aboutus" element={<Team/>} />
            <Route path="home" element={<Home/>} />
            <Route path="values" element={<Values/>} />
            <Route path="services" element={<Services />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/types-of-electric-vehicles" element={<BlogElectricVehicles />} />
            <Route path="blog/detection-of-effusion" element={<BlogElectricXray />} />
            <Route path="blog/iot-and-industry-4.0" element={<BlogIot />} />

        </Routes>
        <Outlet />
      </BrowserRouter>

    </>
  )
}

export default NavBarHead