import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import { Container, Row, Col } from 'reactstrap';
import './Team.css';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Footer from '../Footer'
import GoToTop from '../GoToTop/GoToTop';
// import background from '../../assets/background.png';
import Amit from "../../assets//teamsProfiles/amit_1.jpg";
import Benny from "../../assets/teamsProfiles/Ben_1.jpg";
import Akash from "../../assets/teamsProfiles/Akash_2.jpg";
import Jeevan from "../../assets/teamsProfiles/Jeevan_1.jpg";
import Ballu from "../../assets/teamsProfiles/Balu_1.jpg";
import Basu from "../../assets/teamsProfiles/Basu_1.jpg";
import Istaque from "../../assets/teamsProfiles/Istaque_1.jpg";
import Khushboo from "../../assets/teamsProfiles/khushboo_1.jpg";
import Komali from "../../assets/teamsProfiles/Komali_1.jpg";
import Anila from "../../assets/teamsProfiles/Anila_1.jpg";
import Achal from "../../assets/teamsProfiles/Achal_1.png";
import Magesh from "../../assets/teamsProfiles/Magesh.jfif";
import Mukesh from "../../assets/teamsProfiles/Mukesh.jpg";
import Neeta from "../../assets/teamsProfiles/Neeta_1.jpg";
import Nikitha from "../../assets/teamsProfiles/Nikitha_1.jpg";
import Nisha from "../../assets/teamsProfiles/Nisha_1.jpg";
import NisarS from "../../assets/teamsProfiles/Nisar_s_1.jpg";
import NisarA from "../../assets/teamsProfiles/Nisaer_A_1.jpg";
import Nitesh1 from "../../assets/teamsProfiles/Nitesh_rathor_1.jpg";
import Nitesh2 from "../../assets/teamsProfiles/Niteshrathore_1.jpg";
import Prem from "../../assets/teamsProfiles/Prem_1.jpg";
import Shailja from "../../assets/teamsProfiles/Shailja_1.jpg";
// import Shalini from "../../assets/teamsProfiles/Shalni_1.jpg";
// import Sajeev from "../../assets/teamsProfiles/sajeev_1.jpg";
import Shelly from "../../assets/teamsProfiles/shelly_1.jpg";
import SumitJ from "../../assets/teamsProfiles/Sumit_J_1.jpg";
import SumitG from "../../assets/teamsProfiles/Sumit_G_1.jpg";
import Shuchi from "../../assets/teamsProfiles/Shuchi_1.jpg";
import Vikas from "../../assets/teamsProfiles/Veekas_1.jpg";
import Vivek from "../../assets/teamsProfiles/Vivek_1.jpg";
import Rahul from "../../assets/teamsProfiles/Rahul.jfif";
import Nayan from "../../assets/teamsProfiles/Nayan.jfif";
import Rajesh from "../../assets/teamsProfiles/Rajesh.png";
import Roshni from "../../assets/teamsProfiles/Roshni.png";
import Aditya from "../../assets/teamsProfiles/Aditya.png";
import HiringIcon from '../HiringIcon/hiring';

function Team() {
  const [nav, setNav] = useState(2);

  let teams = [

    { id: 1, name: "Akash Mahale ", role: "Team Lead ", imageUrl: Akash },

    { id: 32, name: "Achal Mahale ", role: "Software Engineer ", imageUrl: Achal },
    

    { id: 3, name: "Balappa Goudi ", role: "Software Engineer ", imageUrl: Ballu },

    { id: 4, name: "Basavaraj Tenginakai ", role: "Software Engineer ", imageUrl: Basu },

    { id: 5, name: "Istaque Hussain ", role: "Software Engineer ", imageUrl: Istaque },

    { id: 31, name: "Khushboo Pandey ", role: "Software Engineer ", imageUrl: Khushboo },

    { id: 2, name: "Jeevan MD  ", role: "Tech Lead", imageUrl: Jeevan },

    { id: 6, name: "Rajesh Rout ", role: "Test Engineer ", imageUrl: Rajesh },

    { id: 7, name: "Komali Pasumarthy  ", role: "Software Engineer ", imageUrl: Komali },

    { id: 8, name: "M Anila", role: "Software Engineer ", imageUrl: Anila },

    { id: 9, name: "Magesh Vasudevan", role: "Technical Lead - Embedded ", imageUrl: Magesh },

    { id: 10, name: "Mukesh Kumar", role: "Senior Software Engineer ", imageUrl: Mukesh },

    { id: 11, name: "Neeta Wadawadgi  ", role: "Senior Software Engineer ", imageUrl: Neeta },

    { id: 12, name: "Nikitha Mahale  ", role: "Software Engineer ", imageUrl: Nikitha },

    { id: 13, name: "Nisha ", role: "Software Engineer ", imageUrl: Nisha },

    { id: 14, name: "Nisar Ahmad Sheikh ", role: "Software Engineer ", imageUrl: NisarS },

    { id: 15, name: "Nisar Ahmed ", role: "Office Administrator and Security Officer", imageUrl: NisarA },

    { id: 16, name: "Nitesh Rathore ", role: "Software Engineer ", imageUrl: Nitesh1 },

    { id: 17, name: "Nitesh Rathore ", role: "Senior Software Engineer ", imageUrl: Nitesh2 },

    { id: 18, name: "Aditya Bandaki  ", role: "Software Engineer", imageUrl: Aditya },

    // { id: 20, name: "Sajeev Manikkoth  ", role: "Senior Program Manager ", imageUrl: Sajeev },

    { id: 21, name: "Shailja Sharma  ", role: "Software Engineer ", imageUrl: Shailja },

    // { id: 22, name: "Shalini Gupta  ", role: "Software Engineer ", imageUrl: Shalini },

    { id: 23, name: "Shelly Pritchard  ", role: "Team Lead ", imageUrl: Shelly },

    { id: 24, name: "Roshini Setru    ", role: "Quality Assurance Lead ", imageUrl: Roshni },

    { id: 25, name: "Sumit Gupta  ", role: "Software Engineer ", imageUrl: SumitG },

    { id: 26, name: "Sumit Joshi   ", role: "Senior Software Engineer ", imageUrl: SumitJ },

    { id: 27, name: "Vikas MD  ", role: "Software Engineer ", imageUrl: Vikas },

    { id: 28, name: "Vivek Gupta   ", role: "Software Engineer ", imageUrl: Vivek },
    { id: 29, name: "Rahul Mishra", role: "UX/UI Designer", imageUrl: Rahul },
    { id: 30, name: "Nayan Patil ", role: "Software Engineer", imageUrl: Nayan },

  ];

  teams.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="aboutTeam" id='aboutTeamSection'>
      <Helmet>
        <title>Team - KibbCom.com</title>
        <meta name="description" content="KibbCom is a Swedish-Indian deep-tech company specializing in communication intensive software development, providing independent research, design and development services." />
      </Helmet>
      <div className='mb-5 aboutUsBannerImage'>
        {/* <img src={background} alt="" id="main" /> */}
        <p className='valuetext'>What it means <div id="box">to be KibbCom</div></p>
      </div>

      <Container>
        <div className='row'>
          <p id="one">
            KibbCom is a Swedish-Indian deep-tech company specializing in communication intensive software development, providing independent research, design and development services. KibbCom is known for its proven ability and domain expertise in crafting a myriad of state-of-the-art software solutions
          </p>
        </div>
      </Container>

      <div>
        <Container>
          <div className='A'>
            <Row>
              <Col
                lg="5"
                md="12"
                xs="12"
              >
                <Button id="three" color="warning">
                  Working Modalities
                </Button>
                <p id="five">
                  KibbCom adheres to development processes  for complex and large systems based on Agile methodology and Lean principles, resulting in minimal waste and increased efficiency. With our global delivery model, we provide onsite, nearshore, offshore, or hybrid capacity with round-the-clock support, regardless of time zone
                </p>
              </Col>
              <Col lg="1" ></Col>
              <Col
                lg="5"
                md="12"
                xs="12">
                <Button id="three" color="warning">
                  Our Vision
                </Button>
                <p id="six">
                  With creative design and development, our vision is to enhance business growth for our customers and deliver market-defining high-quality solutions that create value and reliable competitive advantage
                </p>
              </Col>
              <Col lg="1" ></Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className='teamList mt-5'>
        <Container>
          <div className='A'>
            <Row>
              <Col
                lg="6"
                md="12"
                xs="12"
              >

                <p className='two'>
                  KibbCom Team
                </p>
              </Col>
              <Col lg="6"
                md="12"
                xs="12">
                <div className='B'>
                  <Row>
                    <Col lg="6" md="6" xs="6" className='text-end'>
                      <div className={nav === 2 ? 'mactive' : 'teamnames'} onClick={() => setNav(2)}>Management Team</div>
                    </Col>
                    <Col lg="6" md="6" xs="6" className='text-start'>
                      <div className={nav === 1 ? 'tactive' : 'teamnames' } onClick={() => setNav(1)}>Team</div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Row>
                <div className="container">
                  {nav === 1 ? 
                    <Row lg='12' md='12' xs='12' className='gridrowgap text-center' >
                      {teams.map((team) => (

                        <Col lg="3" md="6" xs="12" className='mb-3' key={team.id}><img
                        className="rounded-circle img grayscale cssanimation sequence fadeInBottom"
                          alt="100x100"
                          id={team.id}
                          src={team.imageUrl}
                          data-holder-rendered="true"
                        />
                          <div className='name'>{team.name}</div>
                          <div className='role'>  {team.role}</div>
                        </Col>
                      ))}
                    </Row>
:
                    <Row lg='12' md='12' xs='12' className='text-center'>
                      <Col lg="3" md="6" xs="12" className='mb-3'><img className="rounded-circle teamimg img grayscale  cssanimation sequence fadeInBottom" alt="100x100" src={Benny}
                        data-holder-rendered="true" />
                        <div className='name'>Ben Boman</div>
                        <div className='role'> Founder and Chairman</div>
                      </Col>
                      <Col lg="3" md="6" xs="12" className='mb-3'><img className="rounded-circle teamimg img grayscale  cssanimation sequence fadeInBottom" alt="100x100" src={Amit}
                        data-holder-rendered="true" />
                        <div className='name'>Amit Singh</div>
                        <div className='role'> CEO and Managing Director</div>
                      </Col>
                    </Row>
                  }
                </div>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
      <HiringIcon />
      <Footer />
      <GoToTop />
    </div>
  );
}
export default Team;