/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Helmet } from "react-helmet";
import './values.css';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import ImageCarousel from './ImageCarousel';
import Footer from '../Footer'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';
function Values() {
  return (
    <div>
      <Helmet>
        <title>Values - KibbCom.com</title>
        <meta name="description" content="At Kibbcom, we strive to build a culture of inclusion that reflects the goals of our stakeholders and society as a whole" />
      </Helmet>
      <div className='hero'>
        <div className='mask'>
          {/* <img className='into-img' src={valuehome} /> */}
        </div>
        <div className='content'>
          <h1>Our Values </h1>
          <p>At Kibbcom, we strive to build a culture of inclusion that reflects <br />the goals of our stakeholders and society as a whole</p>

        </div>
      </div>

      <section className='valuePage'>

        <Container>
          <div className='div2'>
            <p className='divtext'>Our Values</p>
          </div>
          <div className="head-text">
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='flip-card'>
                  <div className="flip-card-inner">
                    <div className="flip-card-front image1">
                      <h3 className="center__text"> Perseverance </h3>
                    </div>
                    <div className="flip-card-back">
                      <h1>Perseverance</h1>
                      <p className='flip-caption-text'>As pioneers, we are competitive, have an unwavering determination to advance what is right and worthwhile, and possess an inner strength that allows us to remain focused on a purpose, an idea, or a task. We enjoy a sense of integrity, push for inventiveness, and are not afraid of taking calculated risks</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='flip-card'>
                  <div className="flip-card-inner">
                  <div className="flip-card-front image2">
                  <h3 className="center__text1"> Process </h3>
                  </div>
                  <div className="flip-card-back">
                    <h1>Process</h1>
                    <p className='flip-caption-text'>As professionals, we strive to create a structured environment and work culture that enables us to provide our clients with state-of-the-art software solutions tailored exclusively for their businesses. With a rigorous set of organized processes we build and bring in the necessary discipline to vitally create and develop high-quality deliverables under tight deadlines</p>
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12 '>
                <div className='flip-card'>
                  <div className="flip-card-inner">
                  <div className="flip-card-front image3">
                    <h3 className="center__text2" > People </h3>
                  </div>
                  <div className="flip-card-back">
                    <h1>People</h1>
                    <p className='flip-caption-text'>As a company, we value each individual and cultivate a supportive, collaborative environment where every member can contribute. We form long-lasting, trusting relationships through mutual respect, loyalty, community involvement, and support that goes beyond the job itself.As a result, we bring a competitive edge through our collective experience</p>
                  </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 col-md-6 col-sm-12 '>
                <div className='flip-card'>
                  <div className="flip-card-inner">
                  <div className="flip-card-front image4">
                    <h3 className="center__text3"> Passion </h3>
                  </div>
                  <div className="flip-card-back">
                    <h1>Passion</h1>
                    <p className='flip-caption-text'>As a team, we believe the work we do matters deeply. We are committed to delivering excellence and innovation through our willingness to take risks. We are motivated by a sense of adventure and the satisfaction of making a difference in the lives of our clients. We come to work each day with a sense of urgency, importance and focus</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
       {/* <section className='valueImageCarousel'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <p className='imgcalvalue'>Our Values</p>
          </div>
        </div>
        <ImageCarousel />
      </section> */}
      <HiringIcon />
      <Footer />
      <GoToTop />
    </div>

  );
}

export default Values;