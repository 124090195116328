import React from 'react';
import {Helmet} from "react-helmet";
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './services.css';
import Footer from '../Footer'
import embedIcon from '../../assets/embedded-icon-img.png'
import iotIcon from '../../assets/iot-icon-img.png'
import electricVehicleIcon from '../../assets/electric-vehical-icon-img.png'
import cloudComputingIcon from '../../assets/cloud-computing-Icon-img.png'
import HiringIcon from '../HiringIcon/hiring';
import GoToTop from '../GoToTop/GoToTop';
function Services() {
    const showContentData = (flag) => {
        var element = document.getElementById(flag);
        element.scrollIntoView({behavior:"smooth", block: "center", inline:"center"});
    }
    return (
        <div>
            <Helmet>
                <title>Services - KibbCom.com</title>
                <meta name="description" content="We integrate innovative thinking, scientific design, sophisticated development, and continuous analysis to ensure you get the best results." />
            </Helmet>
            <div className='serviceBannerImage'>
                <div className='serviceBannerContent'>
                    <h1 className='mb-4'>From strategy <br/>to execution </h1>
                    <p>We integrate innovative thinking, scientific design, sophisticated development, and continuous analysis to ensure you get the best results</p>
                </div>
            </div>

            <section className='serviceFirstSection'>

                <Container fluid>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgColor'>
                            <div className='serviceSectionContent' onClick={() => showContentData('embedded')}>
                                <div className='row'>
                                    <div className='col-md-10 col-sm-8'>
                                        <h3 >Embedded Software Development Services</h3> 
                                        <p>Shaping the Ubiquitous Face of Technology</p>
                                    </div>
                                    <div className='col-md-2 col-sm-4'>
                                        <img className='serviceIcons' src={embedIcon} alt="embedded software"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgImg1'>

                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgImg2'>

                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgColor'>
                            <div className='serviceSectionContent' onClick={() => showContentData('iot')}>
                                <div className='row'>
                                    <div className='col-md-10 col-sm-8'>
                                        <h3 >IoT </h3>
                                        <p>The Transformation of Data into Intelligence</p>
                                    </div>
                                    <div className='col-md-2 col-sm-4'>
                                        <img className='serviceIcons' src={iotIcon} alt="IoT" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgColor'>
                            <div className='serviceSectionContent' onClick={() => showContentData('electricVehicle')}>
                                <div className='row'>
                                    <div className='col-md-10 col-sm-8'>
                                        <h3 >eMobility </h3>
                                        <p><b>Ecological</b> Contribution to Save the Environment</p>
                                    </div>
                                    <div className='col-md-2 col-sm-4'>
                                        <img className='serviceIcons' src={electricVehicleIcon} alt="electric vehicle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgImg3'>

                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgImg4'>

                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgColor'>
                            <div className='serviceSectionContent' onClick={() => showContentData('cloudComputing')}>
                                <div className='row'>
                                    <div className='col-md-10 col-sm-8'>
                                        <h3 >Cloud Computing Services </h3>
                                        <p>The Ultimate Future of Technology</p>
                                    </div>
                                    <div className='col-md-2 col-sm-4'>
                                        <img className='serviceIcons' src={cloudComputingIcon} alt="cloud computing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgColor'>
                            <div className='serviceSectionContent' onClick={() => showContentData('industry40')}>
                                <div className='row'>
                                    <div className='col-md-10 col-sm-8'>
                                        <h3 >Industry 4.0 </h3>
                                        <p>Embracing the Technological Revolution</p>
                                    </div>
                                    <div className='col-md-2 col-sm-4'>
                                        <img className='serviceIcons' src={electricVehicleIcon} alt="industry4.0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 bgImg5'>

                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceSecondSection py-5' id='embedded'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='secondSectionContent'>
                                <h4>Embedded Software Development Services: <br />Shaping the Ubiquitous Face of Technology</h4>
                                <p>With diverse experience and expertise in the domain of Embedded systems, we offer cost-effective end-to-end services to cater to your business needs. A wide range of professional development services is provided by our expert team, ranging from developing state of the art embedded solutions and also connecting existing embedded solutions to backend clouds.</p>
                                <p>KibbCom has extensive experience working with some of the leading global companies on Microcontrollers and SoCs like DA 14585, DA 14695, nRF52832, and ESP32. We provide cutting-edge solutions in various domains including eMobility, Healthcare, and Home Automation working on chips manufactured by renowned semiconductor companies such as Renesas, Dialog, Arm, Nordic Semi, ST Micro, and KibbCom is an expert in a number of semiconductors, including Renesas, Dialog, Arm, Nordic Semi, ST Micro. On some chips, our team has more expertise than most.</p>
                                <p>Our team is proficient in working on multiple ranges of development models offering a customizable engagement structure for global clients working from different geographies and time zones. We provide embedded system architectures that are tailored to your business needs, ensuring quality and on-time delivery, which makes us your true partner.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceFourthSection'></section>

            <section className='serviceSecondSection py-5' id='iot'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='secondSectionContent'>
                                <h4>IoT: The Transformation of Data into Intelligence</h4>
                                <p>Modern technology has made its way into all aspects of daily life, from complex business operations to simple routine activities. We live in an era of worldwide interconnectedness and inclusiveness that relies heavily on technology. </p>
                                <p>With our innovative IoT services (internet of things), we help businesses leverage physical objects as new sources of data to drive smarter processes and operations. Our IoT services provide cost-effectiveness, user-friendliness, ease of use, and exceptionally simple integration and management.</p>
                                <p>KibbCom's end-to-end IoT services enable our clients to gain robust sustainable competitive advantages through effective integration of smart hardware, sensors, and applications without compromising security. With a team of expert engineers, industry-specific experience, and knowledge covering a myriad of nuances, we aim to transform your business with actionable data-driven insights.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceThirdSection'></section>

            <section className='serviceSecondSection py-5' id='electricVehicle'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='secondSectionContent'>
                                <h4>eMobility: Aiding eMobility Businesses across the World</h4>
                                <p>As fossil fuels deplete, vehicular pollution rises and ecological problems like global warming are exacerbated by the 'El Nino effect', eMobility appear to be the future of transportation. As an ecologically conscious brand, we aim to bring a positive change with eMobility and nurture our ecosystem. </p>
                                <p>The eMobility industry has a strong dependence on the IT sector to deliver fully functional end products. The intervention of information technology in the manufacturing and betterment of eMobility is instrumental. </p>
                                <p>With our vision of creating an environmentally and ecologically sustainable world, we provide software development, mobility, system integrations, and other tailored solutions to the rapidly expanding e-mobility businesses globally.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceFifthSection'></section>

            <section className='serviceSecondSection py-5' id='cloudComputing'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='secondSectionContent'>
                                <h4>Cloud Computing Services: The Ultimate Future of Technology</h4>
                                <p>Across the globe, organizations are spending a fortune on services to help them create and manage stacks that are capable of effectively meeting their organization's needs and keeping them competitive. With our cloud computing services, we help businesses distribute workloads in a way that predicts resources, improves overall efficiency, and performance, and streamlines costs.</p>
                                <p>We focus on making our cloud computing systems elastic, scalable, and secure that provide an optimum environment for your business to grow and scale with minimal intrusion. With our customized cloud services, business owners only pay for what they actually use. The agility in our cloud services provides the flexibility to innovate, build and integrate technologies faster than ever.</p>
                                <p>We help clients to deploy changes and introduce updates in a few minutes without instrumental involvement of external elements. With our innovative cloud computing services our clients can experiment to test new ideas and improve user experience. With our cloud-elastic solutions clients can manage the crest and troughs of operational wavelengths during different phases efficiently.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceSixSection'></section>

            <section className='serviceSecondSection py-5' id='industry40'>
                <Container>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='secondSectionContent'>
                                <h4>Industry 4.0: Embracing the Technological Revolution</h4>
                                <p>The rise of Information Technology and its impact on industrial processes are transforming various disciplines on every level, from engineering to quality management to manufacturing. </p>
                                <p>KibbCom aims to harness the maximum potential of the fourth industrial revolution also known as Industry 4.0. This includes improved agility and flexibility in business operations, increased revenue and manufacturing scales along with high-quality outputs.</p>
                                <p>KibbCom helps global clients to embrace Industry 4.0 with an inclusive and broad approach that is aligned with smart manufacturing and improved distribution of products.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='serviceSevenSection'></section>
            <HiringIcon />
            <Footer />
            <GoToTop />
        </div>
    );
}

export default Services;